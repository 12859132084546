<template>
  <section class="w-full bg-white">
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5 px-4 pt-4 pb-2 gap-4 lg:mb-0">
      <div class="field">
        <label for="icon">Fecha inicial</label>
        <input class="p-button-secondary w-full border p-1.5 rounded-md flex-items-center"
               v-model="filters.fechaInicial" type="date">
      </div>
      <div class="field">
        <label>Fecha final</label>
        <input class="p-button-secondary w-full border p-1.5 rounded-md flex-items-center"
               v-model="filters.fechaFinal" type="date">
      </div>
      <div class="field">
        <label for="nombre">Proveedor</label>
        <div>
          <Dropdown
            v-model="filters.proveedor"
            :options="proveedores"
            class="rounded-md border-gray-300 w-full p-inputtext-xs"
            optionLabel="CardName"
            @click="$h.xxsInput"
            panelClass="text-xxs"
            :showClear="true"
            optionValue="CardCode"
            :filter="true"
            placeholder="Seleccione un proveedor"/>
        </div>
      </div>
      <div class="field">
        <label for="nombre">Estado recepción</label>
        <div>
          <Dropdown
            v-model="filters.estado"
            :options="estadosList"
            @click="$h.xxsInput"
            class="rounded-md border-gray-300 w-full p-inputtext-xs"
            panelClass="text-xxs"
            optionLabel="estadoNombre"
            :showClear="true"
            optionValue="estado"
            :filter="true"
            placeholder="Seleccione un estado"/>
        </div>
      </div>
      <div class="field">
        <label>N. Recepción</label>
        <InputText class="w-full mb-1 p-inputtext-xs" v-model="filters.numRecepcion" type="text"/>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5 px-4 py-2 gap-4 lg:mb-0">
      <div class="flex flex-col col-span-2">
        <label>Medicamento</label>
        <div>
          <div class="grid grid-cols-12">
            <Dropdown
              v-model="medicamentoFiltro"
              :options="medicamentosList"
              panelClass="text-xxs"
              @click="$h.xxsInput"
              placeholder="Seleccione un medicamento"
              optionLabel="nombreCompleto"
              optionValue="ItemCode"
              :filter="true"
              @filter="searchMd"
              filterPlaceholder="escribe mas de 5 letras para buscar"
              class="rounded-md border-gray-300 w-full p-inputtext-xs col-span-11"
            />
            <Button class="border-0 p-button-success p-button-rounded p-button-xs col-span-1 ml-2" icon="pi pi-plus"
                    @click="agregarMdFilter"/>
          </div>
          <div v-for="md of filters.mx" :key="md.ItemCode" class="mt-1">
            <Chip :label="md.nombreCompleto" class="text-xxs mt-1" removable
                  @remove="deleteMdFilter($event, md.ItemCode)"/>
          </div>
        </div>
      </div>
      <div class="flex flex-col col-span-2 lg:col-span-1 xl:col-span-1"
           v-if="actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes' && (uCompras || uAdmin || $can('pharmasan.compras.logistica.recepcion-pedidos.administrador-modulo-recepcion-pedidos'))">
        <label>Usuario Asignado</label>
        <Dropdown
          v-model="filters.user"
          :options="usuariosAsignadosList"
          panelClass="text-xxs"
          placeholder="Seleccione un usuario"
          optionLabel="nombre"
          optionValue="id"
          :filter="true"
          filterPlaceholder="buscar usuario"
          class="rounded-md border-gray-300 w-full p-inputtext-xs "
        />
      </div>
      <div class="flex flex-col col-span-2 lg:col-span-1 xl:col-span-1"
           v-if="actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes' && (uCompras || uAdmin || $can('pharmasan.compras.logistica.recepcion-pedidos.administrador-modulo-recepcion-pedidos'))">
        <label>Bodega Distribución</label>
        <Dropdown
          v-model="filters.bodega"
          :options="bodegasList"
          panelClass="text-xxs"
          placeholder="Seleccione una bodega"
          optionLabel="bodega"
          optionValue="WhsCode"
          :filter="true"
          filterPlaceholder="buscar bodega"
          class="rounded-md border-gray-300 w-full p-inputtext-xs "
        />
      </div>
      <div class="flex flex-col col-span-2 lg:col-span-1 xl:col-span-1">
        <label>Estado de distribución</label>
        <Dropdown
          v-model="filters.estado_distrib"
          :options="estadosDistribList"
          @click="$h.xxsInput"
          panelClass="text-xxs"
          placeholder="Seleccione una bodega"
          optionLabel="label"
          optionValue="value"
          :filter="true"
          filterPlaceholder="buscar bodega"
          class="rounded-md border-gray-300 w-full p-inputtext-xs"
        />
      </div>
      <div class="flex flex-col col-span-2 lg:col-span-1 xl:col-span-1"
           v-if="actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes' && (uCompras || uAdmin || $can('pharmasan.compras.logistica.recepcion-pedidos.administrador-modulo-recepcion-pedidos'))">
        <label>Número entrada</label>
        <InputText class="w-full mb-1 p-inputtext-xs" v-model="filters.numEntrada" type="text"/>
      </div>
      <div class="field">
        <label for="nombre">Novedades</label>
        <div>
          <Dropdown
            v-model="filters.nov"
            :options="arrayNovedades"
            class="rounded-md border-gray-300 w-full p-inputtext-xs"
            panelClass="text-xxs"
            optionLabel="label"
            optionValue="value"
            placeholder="Seleccione..."/>
        </div>
      </div>
      <div class="flex flex-col col-span-2 lg:col-span-1 xl:col-span-1">
        <label>Número pedido</label>
        <Chips
          id="numPedidos"
          v-model="filters.numPedido"
          :allowDuplicate="false"
          separator=","
          inputClass="p-inputtext-xs"
          class="text-xs p-inputtext-xs w-full"
          placeholder="Pedidos separados por ,"
        />
      </div>
    </div>
    <div class="flex gap-4 pr-4 mb-4 lg:mb-0 justify-end pt-2">
      <div class="pb-2 flex">
        <Button class="border-0" label="Buscar" @click="searchFilters"/>
      </div>
      <div class="pb-2 flex">
        <Button class="border-0 p-button-outlined p-button-secondary" label="Limpiar" @click="clearFilters"/>
      </div>
    </div>
  </section>
  <div class="pt-6">
    <DataTable
      :value="pedidosList"
      class="p-datatable-xs text-xs"
      dataKey="DocEntry"
      filterDisplay="menu"
      responsiveLayout="scroll"
      v-model:selection="selectedCustomers"
      @row-select="onRowSelect"
      @row-select-all="onRowSelectAll"
    >
      <template #empty>
        No existen recepciones registradas
      </template>
      <template #header>
        <div class="flex justify-end align-items-center">
          <div class="p-input-icon-left"
               v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.liberar-recepciones')">
            <Button icon="pi pi-lock-open" label="Liberar Recepciones" @click="liberarRemisionMasivo"
                    class="p-button-outlined p-button-help p-button-xs"/>
          </div>
          <div class="p-input-icon-left ml-2"
               v-if="actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes'">
            <Button icon="pi pi-info-circle" label="Info" @click="openOverlayInfo"
                    class="p-button-outlined p-button-info p-button-xs"
                    aria-haspopup="true" aria-controls="overlay_panel_info"
            />
            <OverlayPanel ref="opInfo" id="overlay_panel_info" appendTo="body" :showCloseIcon="false"
                          style="width: 350px" :breakpoints="{'960px': '75vw'}">
              <div class="font-bold text-sm">Estados</div>
              <div class="text-xxs">
                <div class="grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 mb-2 items-center">
                  <div>
                    <Badge :value="''" severity="info" class="mr-1"/>
                    Creado
                  </div>
                  <div class="col-span-2">Recepción NO gestionada por SAP</div>
                </div>
                <div class="grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 mb-2 items-center">
                  <div>
                    <Badge :value="''" severity="warning" class="mr-1"/>
                    Procesando
                  </div>
                  <div class="col-span-2">Datos enviados a SAP para su procesamiento.</div>
                </div>
                <div class="grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 mb-2 items-center">
                  <div>
                    <Badge :value="''" severity="success" class="mr-1"/>
                    Procesado
                  </div>
                  <div class="col-span-2">Recepción creada en SAP como una entrada de mercancías.</div>
                </div>
                <div class="grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 mb-2 items-center">
                  <div>
                    <Badge :value="''" severity="danger" class="mr-1"/>
                    Error
                  </div>
                  <div class="col-span-2">Si se presenta algún error en el proceso de creación de entrada de mercancías,
                    podrá seleccionar el estado para obtener detalles del error.
                  </div>
                </div>
              </div>
            </OverlayPanel>
          </div>
        </div>
      </template>
      <Column
        v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.liberar-recepciones')"
        selectionMode="multiple"
        bodyStyle="text-align: center;justify-content: center;"
        headerStyle="text-align: center;justify-content: center;"
        style="min-width: 3rem; max-width: 3rem"
      />
      <Column field="DocEntry" bodyStyle="text-align: center"
              headerStyle="text-align: center;justify-content: center;width:4rem" header="N. Recepción"
              style="min-width: 5rem">
        <template #body="{data}">
          <div>
            <Button :label="`${data.DocEntry}`"
                    class="p-button-link text-black"
                    style="font-size: 0.75rem"
                    @click="openModalRecepcion(data.DocEntry)"
            />
          </div>
        </template>
      </Column>
      <Column field="numero_pedidos" bodyStyle="text-align: center"
              headerStyle="text-align: center;justify-content: center;" header="Basado en pedidos"
              style="min-width: 8rem"/>
      <Column field="DocNum" header="N. Entrada" bodyStyle="text-align: center"
              headerStyle="text-align: center;justify-content: center"
              style="min-width: 5rem"
              v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras')"
      />
      <Column field="CantidadMds" header="Cant. Mx" bodyStyle="text-align: center"
              headerStyle="text-align: center;justify-content: center"
              style="min-width: 5rem"
      />
      <Column field="" header="Distribución almacenes" bodyStyle="text-align: center"
              headerStyle="text-align: center;justify-content: center"
              style="min-width: 8rem"
      >
        <template #body="{data}">
          <div>
            <div v-if="data.linPendDistribucion === 0">
              <Badge :value="'Completada'" class="mr-1 bg-green-200 text-green-800"/>
            </div>
            <div v-if="data.linPendDistribucion > 0">
              <Badge :value="`Pendiente: ${data.linPendDistribucion}`" class="mr-1 bg-yellow-200 text-yellow-800"/>
            </div>
          </div>
        </template>
      </Column>
      <Column field="cantidadNovedades" header="Cant. Novedades" bodyStyle="text-align: center"
              headerStyle="text-align: center;justify-content: center"
              style="min-width: 5rem"
      >
        <template #body="{data}">
          <div v-if="data.cantidadNovedades > 0" class="flex items-center">
            <alertCircleIcon class="w-1/4 text-red-500"/>
            <span class="ml-2">{{ data.cantidadNovedades }} </span>
          </div>
        </template>
      </Column>
      <Column field="createdAt" bodyStyle="text-align: center;"
              headerStyle="text-align: center;justify-content: center;"
              header="Fecha Recepcion" style="min-width: 7rem"/>
      <Column field="cantidadCartas" bodyStyle="text-align: left;"
              headerStyle="text-align: center;justify-content: center;" header="Cartas Compromiso"
              style="min-width: 7rem" class="my-1 py-1 shadow-sm lg:text-xs">
        <template #body="{data}">
          <div class="flex justify-center">
            <div v-if="data.cantidadCartas > 0">
              <strong>Cant. cartas: </strong> <span>{{ data.cantidadCartas }} </span>
            </div>
            <div class="flex items-center" v-if="data.cantCartasFaltantes > 0">
              <strong>Cant. faltante: </strong> <span class="ml-1">{{ data.cantCartasFaltantes }} </span>
              <alert-triangleIcon class=" ml-1 lg:w-1/12 text-yellow-500"/>
            </div>
          </div>
        </template>
      </Column>
      <Column field="CardName" bodyStyle="text-align: center" headerStyle="text-align: center;justify-content: center;"
              header="Proveedor" style="min-width: 10rem"/>
      <Column field="" bodyStyle="text-align: center" headerStyle="text-align: center;justify-content: center;"
              header="Estado" style="min-width: 8rem"
              v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras')">
        <template #body="{data}">
          <div>
            <Badge :value="data.estadoNombre" :class="colorState(data.estadoNombre)"
                   v-if="data.estadoNombre !== 'ERROR'"/>
            <Badge :value="data.estadoNombre" :class="colorState(data.estadoNombre)" v-else
                   v-tooltip.top="data.MsjRespuesta.message ? data.MsjRespuesta.message : ''"/>
          </div>
        </template>
      </Column>
      <!--      <Column field="Area" bodyStyle="text-align: center" headerStyle="text-align: center;justify-content: center;" header="Area" style="color: #4A4A4A; font: 15px/18px;" class="my-1 py-1 shadow-sm lg:text-xs">-->
      <!--        <template #body="{data}">-->
      <!--          <div class="lg:text-xs">-->
      <!--            <p>-->
      <!--              {{ data.Area }}-->
      <!--            </p>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </Column>-->
      <Column field="createdBy" bodyStyle="text-align: center" headerStyle="text-align: center;justify-content: center;"
              header="Usuario Creación" style="min-width: 10rem"/>
      <Column field="NombreUsuarioAsignado" header="Usuario asignado" bodyStyle="text-align: center"
              headerStyle="text-align: center;justify-content: center;"
              style="min-width: 8rem"
              v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras')"
      />
      <Column field="" bodyStyle="text-align: center" headerStyle="text-align: center;justify-content: center;"
              header="Acciones" style="min-width: 15rem" class="my-1 py-1 shadow-sm lg:text-xs"
      >
        <template #body="{data}">
          <div class="lg:text-xs flex gap-4 justify-center" v-if="data.estado === 0">
            <Button
              v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras') && data.IdUsuarioAsignado !== user.user_id && actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes' && !$can('pharmasan.compras.logistica.recepcion-pedidos.administrador-modulo-recepcion-pedidos') && !data.IdUsuarioAsignado"
              icon="pi pi-check-circle" v-tooltip.top="'Tomar recepción'"
              class="p-button-rounded p-button-xs p-button-info" @click="asignarUsuario(data.DocEntry, true)"/>
            <Button
              v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.editar') && ($can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-bodega') || data.IdUsuarioAsignado === user.user_id)"
              icon="pi pi-pencil" v-tooltip.top="'Editar Recepción'"
              class="p-button-rounded p-button-xs p-button-warning" @click="editRecepcion(data.DocEntry)"/>
            <Button
              v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras') && data.IdUsuarioAsignado === user.user_id && actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes' "
              icon="pi pi-lock-open" v-tooltip.top="'Liberar Recepción'" @click="asignarUsuario(data.DocEntry, false)"
              class="p-button-rounded p-button-xs p-button-info"/>
            <Button
              v-if="
                data.Area === 'BODEGA' && data.LineasConLotes === data.CantidadMds &&
                (
                  ($can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-bodega') && uBodega && actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.listar-recepciones' ) ||
                  (uAdmin  || $can('pharmasan.compras.logistica.recepcion-pedidos.administrador-modulo-recepcion-pedidos'))
                )"
              icon="pi pi-send" v-tooltip.top="'Enviar a compras'" @click="envioCompras(data)"
              class="p-button-rounded p-button-xs"/>
            <Button
              v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras') && data.Area === 'COMPRAS' && uCompras && actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes'"
              icon="pi pi-exclamation-circle" v-tooltip.top="'Consultar Operación Bodega'"
              @click="consultarJsonBodega(data)" class="p-button-rounded p-button-xs p-button-danger"/>
            <Button
              v-if="
                $can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.crear-entrada') &&
                data.Area === 'COMPRAS' &&
                uCompras &&
                actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes' &&
                data.linPendDistribucion === 0 &&
                data.LineasConLotes === data.CantidadMds
                "
              icon="pi pi-upload" @click="crearSap(data)" v-tooltip.top="'Crear Entrada De Mercancia(SAP)'"
              class="p-button-rounded p-button-xs p-button-help"/>
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator v-model:first="offset"
               :rows="limit"
               :totalRecords="totalRecords"
               :rowsPerPageOptions="[10,20,30,50]"
               :pageLinkSize="pageLinkSize"
               @page="onPage($event)"
    />
  </div>
  <div>
    <modalRecepcion ref="openModalRcp" @updateList="getPaginate"/>
  </div>
  <div>
    <modalRecepcionBodega ref="openModalRcpBodega"/>
  </div>
</template>

<script>
import { ref, reactive, computed, defineAsyncComponent, onMounted, watch } from 'vue'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'

export default {
  name: 'ListadoRecepciones',
  components: {
    modalRecepcion: defineAsyncComponent(() =>
      import('./modalRecepcion.vue')
    ),
    modalRecepcionBodega: defineAsyncComponent(() =>
      import('./modalJsonBodega.vue')
    )
  },
  setup () {
    const store = useStore()
    const medicamentoFiltro = ref('')
    const selectedCustomers = ref([])
    const user = computed(() => store.getters['auth/user'])
    const _RecepcionPedidosService = new RecepcionPedidosService()
    const opInfo = ref()
    const pedidosList = ref([])
    const proveedores = ref([])
    const bodegasList = ref([])
    const medicamentosList = ref([])
    const usuariosAsignadosList = ref([])
    const openModalRcp = ref()
    const uBodega = ref(false)
    const uCompras = ref(false)
    const uAdmin = ref(false)
    const actuallyRouter = ref('')
    const openModalRcpBodega = ref()
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const totalRecords = ref(0)
    const pageLinkSize = ref(5)
    const router = useRouter()
    const route = useRoute()
    const arrayNovedades = ref([
      { value: '0', label: 'Sin Novedades' },
      { value: '1', label: 'Con Novedades' }
    ])
    const estadosDistribList = ref([
      { value: '', label: 'Todos' },
      { value: '1', label: 'Incompleto' },
      { value: '2', label: 'Completo' }
    ])
    const estadosList = ref([
      { estado: 0, estadoNombre: 'CREADO' },
      { estado: 1, estadoNombre: 'PROCESANDO' },
      { estado: 2, estadoNombre: 'PROCESADO' },
      { estado: 3, estadoNombre: 'ERROR' }
    ])
    const searchFilters = () => {
      getPaginate()
    }
    const clearFilters = () => {
      filters.numRecepcion = []
      filters.proveedor = null
      filters.estado = null
      filters.numPedido = null
      filters.mx = []
      filters.user = null
      filters.bodega = null
      filters.estado_distrib = null
      filters.numEntrada = null
      filters.nov = null
      getPaginate()
    }
    const filters = reactive({
      numRecepcion: [],
      fechaFinal: null,
      fechaInicial: null,
      proveedor: null,
      estado: null,
      numPedido: null,
      area: ['NO POSEE'],
      mx: [],
      user: null,
      estado_distrib: null,
      bodega: null,
      numEntrada: null,
      nov: null
    })
    const openOverlayInfo = (event) => {
      opInfo.value.toggle(event)
    }
    const crearSap = ({ DocEntry }) => {
      openModalRecepcion(DocEntry, true)
    }
    const liberarRemisionMasivo = () => {
      if (!selectedCustomers.value.length) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Seleccione al menos una recepción para liberar',
          showConfirmButton: true
        })
        return
      }
      const params = [...new Set(selectedCustomers.value.map((d) => d.DocEntry))]
      _RecepcionPedidosService.putLiberarRecepcion(params).then(({ data }) => {
        if (data.length) {
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'Recepciones liberadas exitosamente',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true
          }).then((resp) => {
            getPaginate()
            selectedCustomers.value = []
          })
        }
      })
    }
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        fechaInicial: filters.fechaInicial,
        fechaFinal: filters.fechaFinal,
        numPedido: filters.numPedido,
        proveedor: filters.proveedor,
        numRecepcion: filters.numRecepcion,
        mx: filters.mx.map((x) => x.ItemCode),
        user: filters.user,
        bodega: filters.bodega,
        estado_distrib: filters.estado_distrib,
        estado: filters.estado,
        numEntrada: filters.numEntrada,
        nov: filters.nov,
        area: route.name === 'pharmasan.compras.logistica.recepcion-pedidos.listar-recepciones' ? ['BODEGA'] : ['COMPRAS']
      }
    })
    const envioCompras = (data) => {
      Swal.fire({
        icon: 'info',
        title: 'Esta seguro?',
        text: 'Se enviará esta recepción a el area de compras y luego no se podrá modificar. ¿Desea continuar?',
        focusCancel: true,
        showConfirmButton: true,
        showCancelButton: true
      }).then((resp) => {
        if (resp.isConfirmed) {
          _RecepcionPedidosService.enviarCompras(data.DocEntry).then((response) => {
            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: 'Se envió la recepción al area de compras correctamente'
              }).then((resp) => {
                if (resp.isConfirmed) {
                  getPaginate()
                }
              })
            }
          })
        }
      })
    }
    const consultarJsonBodega = ({ DocEntry }) => {
      _RecepcionPedidosService.getJsonBodega(DocEntry).then(({ data }) => {
        openModalRcpBodega.value.openModalBodega(data)
      })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      getPaginate()
    }

    const agregarMdFilter = () => {
      if (!filters.mx.some((a) => a.ItemCode === medicamentoFiltro.value) && medicamentoFiltro.value !== '') {
        const infoMd = medicamentosList.value.find((a) => a.ItemCode === medicamentoFiltro.value)
        filters.mx.push({
          ...infoMd
        })
        medicamentoFiltro.value = ''
      }
    }

    const deleteMdFilter = (event, ItemCode) => {
      filters.mx = filters.mx.filter(a => a.ItemCode !== ItemCode)
    }
    const verificarPermiso = (permiso) => {
      const user = store.getters['auth/user']
      if (!user.admin) {
        const permissions = store.getters['auth/getPermissions']
        if (permissions.some(a => a === permiso)) return true
        return false
      }
      return true
    }
    const getPaginate = () => {
      if (verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras')) {
        filters.area.push('COMPRAS')
        if (route.name === 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes') uCompras.value = true
      }
      if (verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-bodega')) {
        filters.area.push('BODEGA')
        if (route.name === 'pharmasan.compras.logistica.recepcion-pedidos.listar-recepciones') uBodega.value = true
      }
      _RecepcionPedidosService.getRecepciones(params.value).then(({ data }) => {
        pedidosList.value = data.rows.map((a) => {
          a.MsjRespuesta = JSON.parse(a.MsjRespuesta)
          return a
        })
        totalRecords.value = data.count
      })
    }
    const getProveedores = () => {
      _RecepcionPedidosService.getProveedores().then(({ data }) => {
        proveedores.value = data
      })
    }
    const getBodegasList = () => {
      _RecepcionPedidosService.getBodegas().then(({ data }) => {
        bodegasList.value = data
      })
    }
    const getUsuarioAsignados = () => {
      _RecepcionPedidosService.getUsuarioAsignados().then(({ data }) => {
        usuariosAsignadosList.value = data
      })
    }
    const searchMd = async ({ value }) => {
      if (value.length > 5) {
        await _RecepcionPedidosService.getAllMds(value.toUpperCase()).then(({ data }) => {
          medicamentosList.value = data
        })
      }
    }
    const colorState = (estado) => {
      switch (estado) {
        case 'CREADO':
          return 'bg-blue-200 text-blue-800'
        case 'PROCESANDO':
          return 'bg-yellow-200 text-yellow-800'
        case 'PROCESADO':
          return 'bg-green-200 text-green-800'
        case 'ERROR':
          return 'bg-red-200 text-red-800'
      }
    }
    const openModalRecepcion = (DocEntry, previewEntrada = false) => {
      _RecepcionPedidosService.getRecepcionDetails({ DocEntry, previewEntrada }).then(({ data }) => {
        openModalRcp.value.openModal(data, previewEntrada)
      })
    }
    const editRecepcion = (DocEntry) => {
      router.push({
        name: 'pharmasan.compras.logistica.recepcion-pedidos.editar-recepcion',
        params: { DocEntry: DocEntry }
      })
      // router.push(`/pharmasan/compras/logistica/editar-recepcion/${DocEntry}`, { pageIndex: 1 })
    }
    const asignarUsuario = (DocEntry, asignar) => {
      Swal.fire({
        icon: 'question',
        title: '¿ Esta seguro ?',
        text: `Desea ${asignar ? 'tomar' : 'liberar'} la recepción No. ${DocEntry}`,
        showConfirmButton: true,
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(({ value }) => {
        if (value) {
          _RecepcionPedidosService.asignarUsuarioRecep(DocEntry, { asignar }).then(({ data }) => {
            if (data.DocEntry) {
              if (asignar) {
                Swal.fire({
                  icon: 'success',
                  title: 'Asignación exitosa',
                  text: 'Se asigno correctamente la recepción'
                }).then(() => {
                  getPaginate()
                })
              } else {
                Swal.fire({
                  icon: 'success',
                  title: 'Asignación exitosa',
                  text: 'Se libero correctamente la recepción'
                }).then(() => {
                  getPaginate()
                })
              }
            }
          })
        }
      })
    }

    const onRowSelect = ({ data }) => {
      if (data.estadoNombre !== 'CREADO') {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: `La recepción seleccionada no se puede liberar ya que tiene estado ${data.estadoNombre}.`,
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        const indexRecep = selectedCustomers.value.findIndex(x => x.estadoNombre === data.estadoNombre)
        selectedCustomers.value.splice(indexRecep, 1)
      }
    }

    const onRowSelectAll = ({ data }) => {
      if (data.some(a => a.estadoNombre === 'CREADO')) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Algunas recepciones no pueden liberar ya que se encuentran en proceso de creación de entrada de mercancía.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        selectedCustomers.value = selectedCustomers.value.filter((x) => x.estadoNombre === 'CREADO')
      }
    }
    const loadWindow = async () => {
      const date = new Date()
      const now = new Date()
      const lastMonth = date.setMonth(date.getMonth() - 1)
      filters.fechaInicial = dayjs(lastMonth).format('YYYY-MM-DD')
      filters.fechaFinal = dayjs(now).format('YYYY-MM-DD')
      getPaginate()
      getUsuarioAsignados()
      getProveedores()
      getBodegasList()
    }

    watch(() => route.name, async (val) => {
      if (['pharmasan.compras.logistica.recepcion-pedidos.listar-recepciones', 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes'].includes(val)) {
        await validaciones()
      }
    })
    const validaciones = async () => {
      const user = store.getters['auth/user']
      uAdmin.value = user.admin
      actuallyRouter.value = route.name
      if ((uAdmin.value) || (verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.administrador-modulo-recepcion-pedidos'))) {
        await loadWindow()
      } else {
        const validateBod = verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-bodega')
        const validateCompras = verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras')
        if (validateBod && route.name === 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes') {
          router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos' })
        } else if (validateCompras && route.name === 'pharmasan.compras.logistica.recepcion-pedidos.listar-recepciones') {
          router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos' })
        } else {
          await loadWindow()
        }
      }
    }
    onMounted(() => {
      validaciones()
    })
    return {
      pedidosList,
      selectedCustomers,
      estadosList,
      filters,
      getPaginate,
      proveedores,
      getProveedores,
      params,
      totalRecords,
      pageLinkSize,
      offset,
      limit,
      onPage,
      searchFilters,
      openModalRcp,
      openModalRecepcion,
      clearFilters,
      editRecepcion,
      route,
      envioCompras,
      consultarJsonBodega,
      openModalRcpBodega,
      crearSap,
      asignarUsuario,
      user,
      verificarPermiso,
      uCompras,
      uBodega,
      uAdmin,
      liberarRemisionMasivo,
      actuallyRouter,
      colorState,
      medicamentosList,
      searchMd,
      usuariosAsignadosList,
      opInfo,
      bodegasList,
      estadosDistribList,
      openOverlayInfo,
      medicamentoFiltro,
      agregarMdFilter,
      deleteMdFilter,
      arrayNovedades,
      onRowSelect,
      onRowSelectAll
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-multiselect-label ) {
  display: flex !important;
  flex-wrap: wrap !important;
}

.p-chips-token {
  padding: 0.1rem 0.5rem;
  margin-right: 0.4rem;
  margin-top: 2px;
  margin-bottom: 2px;
}

::v-deep(.p-chips-multiple-container) {
  padding: 0.1rem 0.6rem;
  max-height: 70px;
  overflow-y: auto;
}

::v-deep(.p-multiselect-label-container) {
  overflow-y: auto !important;
  max-height: 75px !important;
}

::v-deep(.country-item) {
  margin: 2px !important
}

::v-deep(.multiselect-custom) {
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #71717A;
  width: 100rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-multiselect-panel > .p-multiselect-items-wrapper > .p-multiselect-item) {
  font-size: 0.6rem !important;
  line-height: 1rem !important;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}
</style>
